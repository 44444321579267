<template>

    <div class="card" style="background-color: #fffaec; box-shadow: none; webkit-box-shadow:none;">
        <div class="card-image"  >
            <figure class="image">
                <img :src="getImgUrl(image)" class="is-rounded"/>
            </figure>
        </div>
        <div class="card-content">


            <div class="content has-text-centered">
                <p class="title is-4">{{vorname}}<br>{{name}}</p>
                <button v-on:click="mailForm=true" v-if="!mailForm" class="button is-link">E-Mail schreiben</button>
                <Kontaktformular :empfaenger=empfaenger :showKontaktFormular=mailForm @show-kontaktformular="showKontaktformular" />
            </div>
        </div>
    </div>
</template>

<script>

    import Kontaktformular from '@/components/Kontaktformular.vue'

    export default {

        name: 'Aelteste',
        components: {Kontaktformular},
        props: {
            vorname: String,
            name: String,
            image: String,
            empfaenger: String
        },
        methods: {
            getImgUrl(pic) {
                return require('../assets/' + pic)
            },
            showKontaktformular(showMailmForm) {
                this.mailForm=showMailmForm;
            },
        },
        mounted() {
        },
        data() {
            return {
                isOpen: false,
                mailForm:false
            }
        },

    }
</script>